@import "./assets/css/login.scss";

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/assets/fonts/MaterialIcons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
font-family: 'Material Icons';
font-weight: normal;
font-style: normal;
font-size: 24px;
line-height: 1;
letter-spacing: normal;
text-transform: none;
display: inline-block;
white-space: nowrap;
word-wrap: normal;
direction: ltr;
-moz-font-feature-settings: 'liga';
-moz-osx-font-smoothing: grayscale;
}

body {
    color: #536373;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif !important;
}

.html {
    border: none;
}

#root {
    height: 100%;
    min-height: 100vh;
    background-color: rgb(235, 239, 243);
}

.wrapper {
    // min-height: 100vh;
    // position: relative;
    // padding: 4.2rem 0 6rem;
    padding: 0 1rem 0 1rem;
}

// @media (min-width: 576px){
//     #wrapper {
//         padding: 6rem 0 9rem;
//     }
// }

.card-title-buttons {
    float: right;

    @media (max-width: 576px){       
        margin-top: 5px;
    }

    button {
        margin-right: 0;
        margin-left: 8px;
        background-color: #3f51b5;
        color: #fff !important;
        border: none !important;
        font-size: 12px;        
    }
    button:hover {
        background-color:  #253799;
    }

    @media (max-width: 576px){
        button {
            font-size: 10px;
            margin-left: 0px;
            margin-right: 8px;            
        }
    }
}

.card-ventil-buttons {  
   display: block; 
   clear: both;

    button {
        margin-right: 12px;       
        background-color: #3f51b5;
        color: #fff !important;
        border: none !important;
        font-size: 14px;
        width: 65px;
           
    }
    button:hover {
        background-color:  #253799;
    }
}

@media (max-width: 576px){
    .noRight {
        float: none !important;
    }
}


.container-dynamic {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.module-wrapper {
    padding-bottom: 50px;

    .distancer {
        .row {
            .col {
                .card {
                    margin-bottom: 15px;
                }
            }
        }
    }
}

.padding-small {
    padding-left: 3rem!important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.distancer {
    padding-top: 80px;
}

.navbar.fixed-top {
    border: 0;
    background: white;
    //background: linear-gradient(135deg,#f96 0,#ff5e55 100%);
    box-shadow: rgb(159 162 191 / 18%) 0px 2px 3px, rgb(159 162 191 / 32%) 0px 1px 1px;
}

.navbar.fixed-bottom {
    max-height: 100vh;
    overflow-y: auto;
    background: linear-gradient(135deg,#f96 0,#ff5e55 100%);
    box-shadow: 0 0 0.25rem rgb(83 99 115 / 25%);
}

.navbar {
    padding: .7rem 0;
    background-color: #e05c55;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: .4rem;
    padding-bottom: .4rem;
    box-sizing: border-box;

    .navbar-container {
        margin-right: auto;
        margin-left: auto;
        display: flex;
        flex-wrap: nowrap!important;
        align-items: center;
        justify-content: space-between;

        .navbar-brand {
            display: inline-block;
            padding-top: .3125rem;
            line-height: inherit;
            white-space: nowrap;    
        }
    
        .navbar-logo {
            height: 40px;   
            margin-top: -5px;        
        }

        .navbar-logo-mobile {
            height: 40px;   
            margin-top: -5px;
            display: none;    
        }

        @media (max-width: 576px){       
            .navbar-logo {
                display: none;
            }
            .navbar-logo-mobile {
                display: block;
            }
        }

        
        .navbar-title-wrapper {    
            font-size: 14px;
            margin: 0 auto;    
            text-align: center;        
            text-decoration: none;
            
            .navbar-subtitle {
                font-size: 12px;
                margin: 0 auto;
                text-align: center;   
                color: rgb(90, 90, 90);
            }
        }
    
        .navbar-icons-wrapper {
            width: 140px;            
            
            .logout-button {
                cursor: pointer;
    
                .icons {
                    float: right;
                    display: block;
                    margin: 0 auto;
                    width: 2.2rem;
                    height: 2.2rem;        
                    color: #ec7973;
                    //border-radius: 50%;         
                }
    
                .icons:hover {
                    color: #c43e37; 
                    //background-color: rgba(255, 255, 255, 0.1);
                }
            } 

            .settings-button {
                cursor: pointer;
    
                svg {
                    float: right;
                    display: block;
                    margin-right: 10px;
                    width: 2.2rem;
                    height: 2.2rem;        
                    color: #ec7973;
                    //border-radius: 50%;         
                }
    
                svg:hover {
                    color: #c43e37; 
                    //background-color: rgba(255, 255, 255, 0.1);
                }
            }

            .alert-button {
                
                .icons {
                    float: right;
                    display: block;
                    margin: 0 auto;
                    width: 2.2rem;
                    height: 2.2rem;        
                    color: #b8b8b8;
                    //border-radius: 50%; 
                    margin-right: 10px;
                }
            }

            .settings-button {
                .icons {                    
                    float: right;
                    display: block;
                    margin: 0 auto;
                    width: 2.2rem;
                    height: 2.2rem;        
                    color: #ec7973;
                    //border-radius: 50%; 
                    margin-right: 10px;
                }
                .icons:hover {
                    color: #c43e37;
                }
            }
        }
    }

    #globalSettingsDiv {       
        position: absolute;
        height: 0px;
        width: 380px;        
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        transition: 0.5s;
        overflow: hidden;        
        border-radius: 4px;        
        background-color: white;
        
        .globalSettingsInnerDiv {
            display: none;

            #confirmButtonRow {
                button {
                    margin-right: 12px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .gsRow {
                font-size: 14px;
                color: #666;
                font-weight: 500;
                margin-top: 15px;

                input {
                    -webkit-appearance: none;
                    border: 1px solid #b9b9bb;
                    display: -webkit-flex;
                    display: flex;
                    border-radius: 4px;
                    font-size: 16px;
                    -webkit-flex-grow: 1;
                    flex-grow: 1;
                    -webkit-flex-shrink: 1;
                    flex-shrink: 1;
                    line-height: 28px;
                    padding: 8px 16px;
                    margin-top: 12px;
                    outline-color: #eee3e4 !important;
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }        
    }
    #globalSettingsDiv.showGlobalSettingsDiv {        
        height: 460px;
        padding: 15px;
        top: 80px;
        // outline: 1px solid rgb(231, 231, 231);
        box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;

        .globalSettingsInnerDiv {
            display: block;
        }
    }
}

@media (max-width: 576px){
    .navbar-container {
        padding: 0px 1rem 0px 1rem !important;
    }
}

@media (max-width: 800px){
    .kuppelControlHeader {
        margin-bottom: 3rem !important;
    }
}

.kuppelControlHeader {
    .linkButtons {
        float: right;
    }
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.component-fader {
    opacity: 0;
    transition: 5s opacity;
}

.fade-in {
    opacity: 1;
}



// CARDS
.outer-title {
    margin: 0;
    color: #9e9e9e;
    font-weight: 500;
    font-size: 25px;
    line-height: 1.4;
    padding-bottom: 0.5rem;
}

.outer-subtitle {
    margin: 0;
    color: #9e9e9e;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    padding-bottom: 0.5rem;
}

.distancer-bottom {
    margin-bottom: 1rem;
}

.multi-card-wrapper{ 
    .card {
        margin-bottom: 15px;
    }
}

.card {
    box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;
}

.card .card-title, .card h3 {
    margin: 0;
}

.card-avatar {
    float: left;
    padding: 12px;
    margin-top: -20px;
    margin-right: 5px;
    border-radius: 6px;    
    background-color: #ff6039;

    svg {
        color: #fff;
        height: 44px;
        width: 44px;
    }
}

// .red {
//     background: linear-gradient(60deg, #ef5350, #e53935);
//     box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%);
// }

// .light-blue {
//     background: linear-gradient(60deg, #26c6da, #00acc1);
//     box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
// }

// .yellow {
//     background: linear-gradient(60deg, #f3ef19, #ddca1f);
//     box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 152 0 / 40%);
// }

.inactive_color {
    background: linear-gradient(60deg, #6b6b6b, #6b6b6b);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
}

.dehoust-blue {
    background: linear-gradient(60deg, #0068b4, #0068b4);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
}

.syr-blue {
    background: linear-gradient(60deg, #004a99, #004a99);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(0 172 193 / 40%);
}

.orange {
    //background: linear-gradient(60deg, #ffa726, #fb8c00);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(255 60 0 / 40%);
}

.green {
    background: linear-gradient(60deg, #00b436, #00b436);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgba(0, 193, 26, 0.4);
}

// .green {
//     background: linear-gradient(
//         60deg
//         , #66bb6a, #43a047);
//             box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(76 175 80 / 40%);
// }

.card-icon {
    float: left;
    svg {
        width: 32px;
        height: 32px;
    }
}

.card-title {
    float: left;
    margin: 1;
    color: rgba(83, 99, 115, 0.5);
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    padding-left: 0rem;
    padding-top: 0.35rem;
    color: #536373;
}

.card-title-devices {
    float: left;
    margin: 1;
    color: rgba(83, 99, 115, 0.5);
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    padding-left: 1rem;
    padding-top: 0.35rem;
    color: #536373;
}

.card-title-right {
    float: right;
    margin: 0;
    color: rgba(83, 99, 115, 0.5);
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    padding-left: 1rem;
    padding-top: 0.35rem;
    color: #536373;
}

@media (max-width: 576px){
    .no576 {
        display: none;
    }
}

.device-cards {
    .row {        
        .col {
            .card-header {
                min-width: 400px;
                margin-bottom: 2rem;
                background-color: #fff !important;
                padding-top: 10px !important;
                height: 55px;             
            }
        }
        .col-md-6 {
            .card-header {
                min-width: 400px;
                margin-bottom: 2rem;
                background-color: #fff !important;
                padding-top: 10px !important;
                height: 55px;             
            }
        }
    } 
}




.deviceCard {
    margin-top: 15px;
}

.card-body {
    font-size: 15px;
    a{
        text-decoration: none!important;
    }
}

.card-project-logo {
    height: 42px;
    width: 42px;
    margin-left: 1rem;
}

@media (max-width: 576px){
    .card-project-logo {
        display: none;
    }

    .hideXS {
        display: none !important;
    }
}

.nopad {
    padding-left: 0px !important;
}

.card-project-title {    
    margin-left: 1rem;
    color: rgba(83,99,115,.5);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7;    
    padding-top: 0.35rem;
    color: #536373;
    word-break: keep-all;    
}

.card-project-footer-text {
    float: left;
}

.card-project-toolbox {
    float: right;
    opacity: 0.5;
}

.navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;

    .navbar-nav {
        flex-direction: row;
        margin-left: auto!important;
        margin-right: auto!important;
        display: flex;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
}

.nav-item {
    text-align: center;
    line-height: 14px;
    
    .nav-link {
        color: rgba(255,255,255,.6);        
        text-align: center;
        line-height: 14px;
        padding-left: 16px;
        padding-right: 16px;

        .icons {
            display: block;
            margin: 0 auto;
            width: 3.3rem;
            height: 3.3rem;            
            color: rgba(255,255,255,.6);            
        } 

        span {
            display: inline;
            font-size: 15px;
            font-weight: 400;
            vertical-align: middle;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;            
        } 
    }

    .nav-link.active{
        color: #fff !important;
        .icons {
            color: #fff !important;
        }
        span {
            color: #fff !important;
        }
    }

}

.hide {
    display: none !important;
}

.cardGroupSwitch {
    float: right;
}

.flex {
    display: flex;
}

.borderBox {
    box-sizing: border-box;
}

.bold {
    font-weight: bold;
}

.fltl {
    float: left;
}

.fltr {    
    text-align: right;
}

.floatRight {
    float: right;
}

.disabled {
    opacity: 0.5;
}

.faded {
    opacity: 0.5;
}

.btn-trinkwasser {
    align-content: center;
    text-align: center;
    margin-top: 20px;
    button {
        width: 100%;
    }    
}

.btnGWWrapper {
    align-content: center;
    text-align: center;
    margin-top: 20px;
    button {
        width: 100%;
    }   
}

.btnWCFWrapper {
    align-content: center;
    text-align: center;
    margin-top: 20px;
    button {
        width: 100%;
    }   
}

.btn-wcspulung {
    align-content: center;
    text-align: center;    
    button {
        width: 170px;
        margin-right: 5px;
        margin-left: 5px;        
    }    
}

.grenzewerte-card-body {
       
    .twCardRow {
        margin-bottom: 14px;
        .twCardLabel {
            float: left;
            
        }
        input {
            //float: right !important;
            text-align: right;
            height: 22px;
            margin-right: 5px;
        }
        .grenzwerteUnit {
            width: 32px;
            display: inline-block;
            text-align: left;
        }
    }
}

.signalBars {
    width: 30px;
}

// CUSTOM SWITCH

.custom-switch {
    padding-left: 2.5rem;
  }
  
  .custom-switch  .custom-control-label::before {
    left: -2.5rem;
    width: 2.5rem;
    height: 1.5rem;
    pointer-events: all;
    border-radius: 2rem;
  }
  
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem - 1px);
    width: calc(1.25rem);
    height: calc(1.25rem);
    background-color: #adb5bd;
    border-radius: 50%;
    transition: background-color .2s ease;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .custom-switch  .custom-control-label::after {
      transition: none;
    }
  }
  
  .custom-switch  .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(1rem);
    transform: translateX(1rem);
  }
  
  .custom-switch  .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }



// DASHBOARD PANEL

.dashboard-panel-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .row {
        padding-bottom: 30px;
    }

    .card {
        background-color: white;;
    }
}


// SUBNAV

.back-button {
    background-color: #db423a !important;
    margin-right: 10px !important;

    span:hover {
        text-decoration: none !important;
    }
}

.online {
    border: 2px solid #2eb41c !important;
    color: #2eb41c !important;
    background-color: none !important;
    margin-right: 10px !important;

}

.offline {
    border: 2px solid #d6150b !important;
    color: #d6150b !important;
    background-color: none !important;
    margin-right: 10px !important;

}


.subnav {
    font-size: 14px;
    margin-bottom: 2rem;
    height: 40px;
    width: 100%;

    div {
        a {
            text-decoration: none !important;
        }
    }
    
    .spacer {
        clear: both;
    }
    
    .button-body {
        height: 34px;
        line-height: 24px;
        padding: 4px 14px 4px 10px;
        border-radius: 3px; 
        text-decoration: none;
        display: inline-block;
        
        svg {
            float: left;            
            width: 20px;
            height: 20px;
            margin-top: 2px;
            margin-right: 12px;
        }

        .white {
            color: white;
        }
        
        .subnav-title {
            float: left;            
            text-transform: capitalize;   
            font-weight: 500; 
        }

        .icon-arrow-right{
            float: left;            
            width: 16px;
            height: 16px;
            transform: rotate(180deg);
            margin-top: 4px;
            margin-right: 12px;
        }    
        
        .icon-plus {
            float: left;            
            width: 16px;
            height: 16px;
            margin-top: 4px;
            margin-right: 12px;       
        }
    }

    .button-red {
        background-color: #e05c55;
        border: 1px solid #e05c55;
        color: rgb(255, 255, 255);

        .icon {
            color: white;
        }
    }

    .button-red:hover {
        background-color: #f0807a;
        border: 1px solid #f0807a;
    }

    .button-red:active {
        background-color: rgb(187, 81, 76);
        border: 1px solid rgb(187, 81, 76);
    }   

    .button-clear {        
        color: #536373;
        border: 1px solid #abbac9;
        // box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 8%) 0px 1px 1px 0px, rgb(60 66 87 / 12%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 6%) 0px 2px 5px 0px;
    }
}

.groupLightswitch {
    height: 40px;

    .cardLabel {
        float: left;
    }
    .cardGroupSwitch {
        float: right;
    }
}

.groupLightswitch14 {
    display: none!important;
}
.groupLightswitch22 {
    display: none!important;
}

.lightSwitch {
    width: 20px;
    height: 20px;
}

// RAUCHMELDEPLAN 

.lageplan-card {
    padding-top: 8px !important;
    background-color: rgba(235, 239, 243, 0) !important;
    box-shadow: none !important;
    border: none !important;

    .card-body {
        padding: 0 !important;

        nav {
            a {
                margin-left: 10px;
                padding: 6px 16px;
                font-size: 0.875rem;
                font-weight: 500;
                border: none;
                border-radius: 4px;
                line-height: 1.75;
                min-width: 64px;
                letter-spacing: 0.02857em;
            }
        }
    }
}

.lageplan-tab {
    margin-top: 2%;
    background-color: white;
    padding: 2%;
    border-radius: 6px;
    box-shadow: rgb(159 162 191 / 18%) 0px 9px 16px, rgb(159 162 191 / 32%) 0px 2px 2px;
}

.rauchmelderPlan {
    width: 100%;
}

.planRWM {
    position: absolute;
    width: 1.5%;
    height: 2%;
    background-color: green;
    border: 1px sold white;
}

.planRWMAlarm{
    background-color: red !important;
}

#RM101RWM1 {
    left: 78%;
    top: 23%;
}

#RM102RWM1 {
    left: 60%;
    top: 23%;
}

#RM103RWM1 {
    left: 60%;
    top: 36%;
}

#RM104RWM1 {
    left: 55%;
    top: 38%;
}

#RM105RWM1 {
    left: 56%;
    top: 59%;
}

#RM106RWM1 {
    left: 66%;
    top: 57%;
}

#RM106RWM2 {
    left: 77%;
    top: 57%;
}

#RM107RWM1 {
    left: 84%;
    top: 40%;
}

#RM108RWM1 {
    left: 73%;
    top: 37%;
}

#RM108RWM2 {
    left: 57%;
    top: 46%;
}

#RM109RWM1 {
    left: 80%;
    top: 44%;
}

#RM1010RWM1 {
    left: 80%;
    top: 40%;
}

#RM201RWM1 {
    left: 35%;
    top: 45%;
}

#RM201RWM2 {
    left: 25%;
    top: 40%;
}

#RM202RWM1 {
    left: 7%;
    top: 45%;
}

#RM202RWM2 {
    left: 17%;
    top: 40%;
}

#RM203RWM1 {
    left: 7%;
    top: 56%;
}

#RM203RWM2 {
    left: 7%;
    top: 66%;
}

#RM204RWM1 {
    left: 7%;
    top: 80%;
}

#RM204RWM2 {
    left: 17%;
    top: 82%;
}

#RM205RWM1 {
    left: 26%;
    top: 82%;
}

#RM206RWM1 {
    left: 35%;
    top: 82%;
}

#RM207RWM1 {
    left: 35%;
    top: 66%;
}

#RM208RWM1 {
    left: 31%;
    top: 53%;
}

#RM208RWM2 {
    left: 21%;
    top: 63%;
}

#RM209RWM1 {
    left: 20%;
    top: 68%;
}

#RM2010RWM1 {
    left: 18%;
    top: 74%;
}

#RM2011RWM1 {
    left: 21%;
    top: 74%;
}

#RM301RWM1 {
    left: 45%;
    top: 70%;
}

#RM302RWM1 {
    left: 47%;
    top: 50%;
}

/// STOCKWERK 01

#RM111RWM1 {
    left: 63%;
    top: 30%;
}

#RM111RWM2 {
    left: 74%;
    top: 29%;
}

#RM112RWM1 {
    left: 60%;
    top: 36%;
}

#RM113RWM1 {
    left: 60%;
    top: 50%;
}

#RM114RWM1 {
    left: 68%;
    top: 49%;
}

#RM115RWM1 {
    left: 82%;
    top: 55%;
}

#RM116RWM1 {
    left: 81%;
    top: 44%;
}

#RM116RWM2 {
    left: 79%;
    top: 35%;
}

#RM117RWM1 {
    left: 77%;
    top: 39%;
}

#RM117RWM2 {
    left: 58%;
    top: 42%;
}

#RM211RWM1 {
    left: 34%;
    top: 44%;
}

#RM212RWM1 {
    left: 26%;
    top: 39%;
}

#RM213RWM1 {
    left: 11%;
    top: 44%;
}

#RM214RWM1 {
    left: 9%;
    top: 57%;
}

#RM214RWM2 {
    left: 9%;
    top: 64%;
}

#RM215RWM1 {
    left: 7%;
    top: 80%;
}

#RM216RWM1 {
    left: 16%;
    top: 82%;
}

#RM217RWM1 {
    left: 26%;
    top: 82%;
}

#RM218RWM1 {
    left: 35%;
    top: 80%;
}

#RM219RWM1 {
    left: 33%;
    top: 66%;
}

#RM2110RWM1 {
    left: 32%;
    top: 59%;
}

#RM2112RWM1 {
    left: 21%;
    top: 57%;
}

#RM2113RWM1 {
    left: 17%;
    top: 75%;
}

#RM2114RWM1 {
    left: 24%;
    top: 75%;
}

#RM311RWM1 {
    left: 46%;
    top: 64%;
}

#RM312RWM1 {
    left: 49%;
    top: 53%;
}

////////////

#RM221RWM1 {
    left: 26%;
    top: 48%;
}

#RM222RWM1 {
    left: 20%;
    top: 51%;
}

#RM223RWM1 {
    left: 14%;
    top: 57%;
}

#RM224RWM1 {
    left: 17%;
    top: 72%;
}

#RM225RWM1 {
    left: 23%;
    top: 74%;
}

#RM226RWM1 {
    left: 27%;
    top: 75%;
}

#RM227RWM1 {
    left: 29%;
    top: 68%;
}

#RM228RWM1 {
    left: 29%;
    top: 62%;
}

#RM229RWM1 {
    left: 21%;
    top: 57%;
}

#RM2210RWM1 {
    left: 19%;
    top: 68%;
}

#RM2211RWM1 {
    left: 23%;
    top: 68%;
}

#RM321RWM1 {
    left: 46%;
    top: 63%;
}

#RM322RWM1 {
    left: 45%;
    top: 49%;
}


/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .tempChart-Wrapper {
    .tempChart-SensorSelect {
        margin-top: 15px;
        button {
           margin-right: 5px;
           margin-top: 5px;
           
            @media (max-width: 576px){
                font-size: 12px;
            }
        }
    }

    .tempChart-SensorCard {
        margin-top: 15px;
        margin-bottom: 30px;
    }

    .tempChart-card {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    button {
    margin-right: 5px;

        @media (max-width: 576px){
            font-size: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .MuiButton-label {
            svg {
                @media (max-width: 576px){
                    height: 17px;
                }
            }
        }
    }
  }

// CONFIG

#greyOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 800px;

    z-index: 111;
    background-color: #000000;
    opacity: 0.2;
}

#configWindow {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 999;

    width: 450px;
    height: 665px;

    background-color: #ffffff;
    border-radius: 7px;

    color: black;
    padding: 15px;

    border: 2px solid #bbc3cc;
    box-shadow: rgba(159, 162, 191, 0.5) 2px 9px 16px, rgba(159, 162, 191, 0.32) 0px 4px 4px;
}

.configWindowTitle {
    text-align: left;
    display: block;
    color: rgba(83, 99, 115, 0.5);
    font-size: 17px;
    font-weight: 500;
    line-height: 1.2;
    color: #536373;
}

.configWindowButtons {
    text-align: center;
    
    button {
        display: inline;
    }
}

.colorSelect1 {
    background-color: #5264f3;
    color: white;
    line-height: 14px;
}

.configButton {
    float: right;
    span {
        svg {
            height: 24px;
            width: 20px;
        }
    }
}

.configButtonFlipped {
    float: right;
    span {
        svg {
            height: 24px;
            width: 20px;
            transform: rotate(180deg);
        }
    }
}

.btnSuccess {
    background-color: rgb(57, 170, 57) !important;
    color: white !important;
}

.btnCancel {
    background-color: rgb(204, 92, 88) !important;
    color: white !important;
}

.textGreen {
    color: #2eb41c;
}

.textRed {
    color: #DB423A;
}

.textBold {
    font-weight: bold;
}

.btnGreyed {
    background-color: rgb(143, 143, 143) !important;
}

.titleProjectCount {   
    font-size: 12px;
    text-align: right;
    padding-left: 10px;
}

.projectCard {
    margin-bottom: 15px;
}

.ecbutton {
    margin-bottom: 10px !important;
}

.settingsDivWrapper {
    height: 400px;
    width: 400px;
    background-color: #fff;
}

.galleryImg {
    height: 120px;
    padding: 5px;
}

.scheibeWaiting {
    color:rgb(204, 92, 88)
}

.spanWidthSetterSyr {
    width: 210px;
    display: inline-block;
}

.syrTable {
    div {
        padding: 3px 0px 3px 15px;
    }
}

.syrTableColorSetter {
    background-color: #eeeeee;
}


.btnWPWrapper {
    display: block;
    position: relative;   
}

.btnWCSPWrapper {
    display: block;
    position: relative;
    margin-bottom: 10px;
}

.sliderWrapper {
    padding: 10px;
}

//btnGWWithProgress
//btnWCFWrapper

.wcFlushButton{
    position: absolute;
    top: 88px;
    left: 50%;    
    
    transform: translate(-50%, -50%);
    //height: 95px;
    width: 140px;
    background: #303f9f;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    overflow: hidden;
    padding: 7px 16px;
  }

  .wcFlushButton:hover {
    background: #29347c;
  }

  .wcFlushButtonGreen {
    background: #3d9f30 !important;
  }

  .wcFlushButton.active{
    height: 20px;
    width: 200px;
  }
  .wcFlushButton::before{
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: #3d9f30;
    border-radius: 4px;
    transition: all 5s ease-in-out;
  }
  .wcFlushButton.active::before{
    animation: layer 5s ease-in-out forwards;
  }
  @keyframes layer {
    100%{
      left: 0%;
    }
  }
  .wcFlushButton .soft{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    transition-delay: 0.2s;
  }
  .wcFlushButton.active .soft{
    transform: translateY(60px);
  }
  .wcFlushButton .soft i,
  .wcFlushButton .soft .btn-text{
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
  }
  .wcFlushButton .soft .btn-text{
    font-size: 0.875rem;   
  }

  .magic-btn {
      box-shadow: none !important;
      background-color: #fff !important;
      height: 1px !important;
      cursor: default !important;
  }

  @media (max-width: 1200px){
    .magic-btn {
        display: none !important;
    }
}


.grenzwerteButton{
    position: absolute;
    top: 210px;
    left: 50%;    
    
    transform: translate(-50%, -50%);
    //height: 95px;
    width: 140px;
    background: #303f9f;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    overflow: hidden;
    padding: 7px 16px;
  }

  .grenzwerteButton:hover {
    background: #29347c;
  }

  .grenzwerteButtonGreen {
    background: #3d9f30 !important;
  }

  .grenzwerteButton.active{
    height: 20px;
    width: 200px;
  }
  .grenzwerteButton::before{
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: #3d9f30;
    border-radius: 4px;
    transition: all 2s ease-in-out;
  }
  .grenzwerteButton.active::before{
    animation: layer 2s ease-in-out forwards;
  }
  @keyframes layer {
    100%{
      left: 0%;
    }
  }
  .grenzwerteButton .soft{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    transition-delay: 0.2s;
  }
  .grenzwerteButton.active .soft{
    transform: translateY(60px);
  }
  .grenzwerteButton .soft i,
  .grenzwerteButton .soft .btn-text{
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
  }
  .grenzwerteButton .soft .btn-text{
    font-size: 0.875rem;   
  }

  .magic-btn {
      box-shadow: none !important;
      background-color: #fff !important;
      height: 1px !important;
      cursor: default !important;
  }

  @media (max-width: 1200px){
    .magic-btn {
        display: none !important;
    }
}

.btnWithProgress{
    position: absolute;
    top: -10px;
    right: -70px;
    
    transform: translate(-50%, -50%);
    //height: 95px;
    width: 180px;
    background: #303f9f;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    overflow: hidden;
    padding: 7px 16px;
  }

  .btnWithProgress:hover {
    background: #29347c;
  }

  .btnWithProgressGreen {
    background: #3d9f30 !important;
  }

  .btnWithProgress.active{
    height: 20px;
    width: 200px;
  }
  .btnWithProgress::before{
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: #3d9f30;
    border-radius: 4px;
    transition: all 2s ease-in-out;
  }
  .btnWithProgress.active::before{
    animation: layer 2s ease-in-out forwards;
  }
  @keyframes layer {
    100%{
      left: 0%;
    }
  }
  .btnWithProgress .soft{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    transition-delay: 0.2s;
  }
  .btnWithProgress.active .soft{
    transform: translateY(60px);
  }
  .btnWithProgress .soft i,
  .btnWithProgress .soft .btn-text{
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
  }
  .btnWithProgress .soft .btn-text{
    font-size: 0.875rem;   
  }


  .magic-btn {
      box-shadow: none !important;
      background-color: #fff !important;
      height: 1px !important;
      cursor: default !important;
  }

  @media (max-width: 1200px){
    .magic-btn {
        display: none !important;
    }
}

.underMaintenance {
    cursor: pointer;
}

.clickableText {
    cursor: pointer;
    color:rgb(91, 157, 255)!important;
}

.passwordChangeStatusText {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: 15px;
}

.emptyContent {
    height: 16px;
}

.lightsSched{

    div {
        margin-bottom: 8px;
    }
   
    input {
        width: 30px;
        padding: 3px;        
    }
}

.lightsSchedSet {
    div {
        margin-bottom: 8px;
    }
   
    input {
        width: 48px;
        padding: 3px;
    }
}

.clearfix {
    overflow: auto;
}

.react_time_range__time_range_container {
    width: 100% !important;
    padding: 10px 0px 10px 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

@media (max-width: 470px) {
    .react_time_range__tick_label {
        display: none !important;
    }
}

#deleteSchedBtn {
    background-color: #db423a;
    color: white;
}

.MuiButton-containedAlert {
    background-color: #db423a;
    color: white;
}

///////////////////////////////// BUTTON EC

.btnWithProgressEC{
    position: absolute;
    top: 25px;
    right: -70px;
    
    transform: translate(-50%, -50%);
    width: 180px;
    background: #303f9f;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    overflow: hidden;
    padding: 2px 16px;
  }

  .btnWithProgressEC:hover {
    background: #29347c;
  }

  .btnWithProgressECGreen {
    background: #3d9f30 !important;
  }

  .btnWithProgressEC.active{
    height: 20px;
    width: 200px;
  }
  .btnWithProgressEC::before{
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: #3d9f30;
    border-radius: 4px;
    transition: all 2s ease-in-out;
  }
  .btnWithProgressEC.active::before{
    animation: layerEC 2s ease-in-out forwards;
  }
  @keyframes layerEC {
    100%{
      left: 0%;
    }
  }
  .btnWithProgressEC .soft{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    transition-delay: 0.2s;
  }
  .btnWithProgressEC.active .soft{
    transform: translateY(60px);
  }
  .btnWithProgressEC .soft i,
  .btnWithProgressEC .soft .btn-text{
    color: #fff;
    font-size: 0.875rem;
    font-weight: bold;
  }
  .btnWithProgressEC .soft .btn-text{
    font-size: 0.875rem;   
  }

  .hidden {
    display: none;
  }

  .sheetDataFooter {
    padding: 18px;
    .sheetDataTable {    
        td {
            width: 100px;
            border: 1px solid black;
            padding: 4px;
        }
      }
  }

  .isNotDebug {
    .fensterCard {
        .card {
            .sheetDataFooter {
                display: none;
            }
        }
    }
  }

.debugCheckboxWrapper {

    font-size: 0.875rem;
    float: right;
    font-family: Roboto, Helvetica, Arial;
    font-weight: 500;

    input {
        margin-right: 5px;        
    }
}

.FWwrapper { 
    margin: 0 auto;
    display: flex;
	align-items: center;
    justify-content: center;
}

$rayHeight: 400px;
$teal1: #AFE0E4;
$teal2: #C0E6E9;
$teal3: #84CDD4;
$teal4: #7EC9D1;
$teal5: #97D6DC;
$teal6: #AEDEE2;
$teal7: #96D3D8;
$teal8: #9CD7DD;
$teal9: #7ECBD3;
$teal10: #AFE0E4;
$teal11: #B6DFE3;
$teal12: #84CDD4;

.FWcontainer {
	position: relative;
	width: 160px;
	height: 335px;
	background: linear-gradient(to bottom, #d3d9d9 0%, #d4d9d8 30%, #d7d7d7 100%);
	border-radius: 40px;
	overflow: hidden;
}

.gradientContainer {
	// border: 1px solid #ff9900;	
	height: 300px;
	overflow:hidden;
	background: linear-gradient(to bottom, #C3E5E5, #0D3F53);
	position: absolute;
	z-index: 200;
}
.overlay {
	width: 100%;
	height: 240px;
	position: absolute;
	&.one {
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 10%, #0B4558 100%);
		z-index: 100;
	}
}

.gradient {
	margin-top: -100px;
	// border: 1px solid red;
	width: 620px;
	height: 400px;
	transform: translateX(-50px) rotate(20deg);
	animation-name: gradient;
	animation-duration: 10s;
	animation-iteration-count: infinite;
	animation-fill-mode: forwards;
	animation-timing-function: linear;
	transform-style: preserve-3d;
}

@keyframes gradient {
	0% {
		transform: translateX(-50px) rotate(20deg);
	}
	100% {
		transform: translateX(-379px) rotate(20deg);
	}
}

@for $i from 1 through 26 {
	.ray#{$i} {
		height: $rayHeight;
		display: inline-block;
		animation-name: blinkRay;
		animation-duration: random(4) + s;
		animation-delay: random(4) + s;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		animation-timing-function: linear;
		opacity: 1;
	}
}

@keyframes blinkRay {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0.4;
	}
}

.ray1, .ray14, .ray27 { width: 20px; background: linear-gradient(to bottom, $teal1 0%, $teal1 40%, rgba(255, 255, 255,0) 100%); }
.ray2, .ray15, .ray28 { width: 20px; background: linear-gradient(to bottom, $teal2 0%, $teal2 30%, rgba(255, 255, 255,0) 100%); }
.ray3, .ray16, .ray29 { width: 15px; background: linear-gradient(to bottom, $teal3 0%, $teal3 20%, rgba(255, 255, 255,0) 100%); }
.ray4, .ray17, .ray30 { width: 5px; background: linear-gradient(to bottom, $teal4 0%, $teal4 10%, rgba(255, 255, 255,0) 100%); }
.ray5, .ray18, .ray31 { width: 10px; background: linear-gradient(to bottom, $teal5 0%, $teal5 40%, rgba(255, 255, 255,0) 100%); }
.ray6, .ray19, .ray32 { width: 10px; background: linear-gradient(to bottom, $teal6 0%, $teal6 30%, rgba(255, 255, 255,0) 100%); }
.ray7, .ray20, .ray33 { width: 10px; background: linear-gradient(to bottom, $teal7 0%, $teal7 20%, rgba(255, 255, 255,0) 100%); }
.ray8, .ray21, .ray34 { width: 5px; background: linear-gradient(to bottom, $teal8 0%, $teal8 40%, rgba(255, 255, 255,0) 100%); }
.ray9, .ray22, .ray35 { width: 25px; background: linear-gradient(to bottom, $teal9 0%, $teal9 60%, rgba(255, 255, 255,0) 100%); }
.ray10, .ray23, .ray36 { width: 10px; background: linear-gradient(to bottom, $teal5 0%, $teal5 50%, rgba(255, 255, 255,0) 100%); }
.ray11, .ray24, .ray37 { width: 10px; background: linear-gradient(to bottom, $teal6 0%, $teal6 40%, rgba(255, 255, 255,0) 100%); }
.ray12, .ray25, .ray38 { width: 10px; background: linear-gradient(to bottom, $teal7 0%, $teal7 20%, rgba(255, 255, 255,0) 100%); }
.ray13, .ray26, .ray39 { width: 5px; background: linear-gradient(to bottom, $teal8 0%, $teal8 10%, rgba(255, 255, 255,0) 100%); }

.cPos {
	position: relative;
	top: 30%;
	left: calc(50% - 80px);
	z-index: 9;
}

@keyframes sunset {
	0% {
		transform: translateY(0) scale(1);
	}
	100% {
		transform: translateY(10px) scale(.9);
	}
}
.cc {
	display: flex;
	align-items: center;
	justify-content: center;
}
.circle {
	position: absolute;
	background: #FBFEF9;
	border-radius: 50%;
	&.one {
		width: 160px;
		height: 160px;
		opacity: .2;
	}
	&.two {
		width: 130px;
		height: 130px;
		opacity: .2;
	}
	&.three {
		width: 100px;
		height: 100px;
		opacity: .2;
	}
	&.four {
		width: 70px;
		height: 70px;
	}
}

.bubbleContainer {
	width: 100%;
	height: 100px;
	bottom: 0;
	position: absolute;
	animation-name: bubblesIn;
	animation-duration: 2s;
	animation-iteration-count: 1;
	animation-timing-function: linear;
}

@keyframes bubblesIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@for $i from 1 through 10 {
	$size: random(4) + 1 + px;

	.bubbleX#{$i} {
		display: inline-block;
		width: $size;
		height: $size;
		border-radius: 50%;
		background: #FFF;
		transform: translateX(0);
		animation-name: bubbleX#{$i};
		animation-duration: 4s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
	.bubbleY#{$i} {
		display: inline-block;
		position: absolute;
		z-index: 5000;
		top: random(100) + px;
		left: random(156) + px;
		animation-name: bubbleY;
		animation-duration: 1.25s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		transform: translateY(0);
		opacity: .6;
		animation-delay: random(3) + s;
	}
	@keyframes bubbleX#{$i} {
		0% { transform: translateX(0); }
		20% { transform: translateX(-random(15) + px) }
		40% { transform: translateX(random(15) + px) }
		60% { transform: translateX(-random(15) + px) }
		80% { transform: translateX(random(15) + px) }
		100% { transform: translateX(-random(15) + px); }
	}
	
	@keyframes bubbleY {
		0% {
			transform: translateY(0);
		}
		50% {
			transform: translateY(-30px);
		}
		100% {
			transform: translateY(-60px);
			opacity: 0;
		}
	}
}

.triangleContainer {
	margin-top: 92px;
	width: 1000px;
	position: absolute;
	z-index: 1000;
	transform: translateX(-250px);
	animation-name: triangles;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
.triangleBar {
	width: 1000px;
	height: 6px;
	position: absolute;
	z-index: 1500;
	background: linear-gradient(to bottom, #E7F9F3, #F5FFF9);
}

.triangle {
	width: 50px;
	height: 10px;
	display: inline-block;
	background: #EDF7E7;
	clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 0% 0%);
}

@keyframes triangles {
	0% {
		transform: translateX(-250px);
	}
	100% {
		transform: translateX(-500px);
	}
}
.fin {
	position: absolute;
	top: 75%;
	left: 50%;
	z-index: 5000;
	width: 50px;
	height: 30px;
	background: #407A8B;
	transform-origin: center top;
	clip-path: polygon(30% 50%, 60% 0, 100% 0%, 80% 60%, 60% 80%, 0 100%);
	animation-name: fin;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;
}

@keyframes fin {
	0% {
		transform: rotate3d(0, 0, 0, 0deg);
	}
	100% {
		transform: rotate3d(1, 1, 1, 30deg);
	}
}

.FWlabel {
position: absolute;
text-align: center;
font-weight: bold;
width: 60px;
left: 55px;
top: -32px;
z-index: 99999;
color:rgb(90, 90, 90);
border-bottom: 1px dashed rgb(148, 148, 148);
}