#topContentWrapper {
	background-color: #0053a3;
	padding: 2px;
	margin-bottom: 1em;
}

	#topContentWrapper.warning {
		background-color:#fb2525;
	}

#topContent {
	text-align: center;
	color: #fff;
}

#pump {
	height: 6.2%;
	left: 43%;
	position: absolute;
	top: 1.8%;
}

.pumpOuterRw {
	top: 40%;
	left: 70.3%;
}

.waterBigTank {
	background-color: #0053a3;
	bottom: 0;
	height: 0%;
	left: 0;
	position: absolute;
	width: 100%;
	z-index: 5;
}

.BigTank {
	top: 73px;/*17.5%;*/
	height: 228px;/*40%;*/
	left: 150px;/*43.2%;*/
	position: absolute;
	width: 105px;/*17.1%;*/
	background-color: none;
	cursor: default;
	border: 5px solid black;
	border-bottom-left-radius: 40px;
	border-bottom-right-radius: 40px;
	border-top: 0;
	overflow: hidden;
	padding-top: 50px;
}

.cisternTop {
	border: 5px solid black;
	width: 30px;
	height: 40px;
	position: absolute;
	top: 250px;
	left: 377px;
	z-index: 9900;
	border-bottom-color: white;
}

#rwTank {
	top: 295px;
	height: 45px;
	left: 339px;
	position: absolute;
	width: 170px;
	background-color: none;
	cursor: default;
	border: 5px solid black;
	border-bottom-left-radius: 25px 75px;
	border-bottom-right-radius: 25px 75px;
	/* border-radius: 35px; */
	border-top-left-radius: 25px 75px;
	border-top-right-radius: 25px 75px;
	border-top: 1;
	overflow: hidden;
	padding-top: 50px;
}
/*#rwTank {
	top: 73px;
	height: 173px;
	left: 339px;
	position: absolute;
	width: 95px;
	background-color: none;
	cursor: default;
	border: 5px solid black;
	border-bottom-left-radius: 40px;
	border-bottom-right-radius: 40px;
	border-top: 0;
	overflow: hidden;
	padding-top: 50px;
}*/

.rwTankLabel {
	position: absolute;
	right: 22%;
	top: 20%;
}

.fillingLevel {
	width: 100%;
	text-align: center;
	position: absolute;
	top: 44%;
	left: 0;
	z-index: 100;
}

#rwTank .fillingLevel{
	top:74%;
}

.outputLabel {
	padding: 2px 5px;
	min-height: 30px;
	
}

	.outputLabel.red {
		background-color: #fb2525;
	}

	.outputLabel.green {
		background-color: #8bc636;
	}

	.outputLabel.yellow {
		background-color: #ffd800;
	}

.fillingLevel .outputLabel {
	background-color: #fff;
}

.systemPressure {
	left: 50%;
	margin-left: -100px;
	position: absolute;
	top: 57.2%;
	width: 200px;
}

.valveStatus {
	position: absolute;
	/*height:1%;*/
}

#valve5 {
	top: 23px;/*2%;*/
	left: 265px;/*403px;*/
}

#valve0 {
	top: 44px;/*10%;*/
	left: 210px; /*351px;*/
}

#valve1 {
	top: 125px;
	left: 0px;
}

#valve2 {
	top: 153px;/*34%;*/
	left: 260px;/*403px;*/
}

.WasserZaehler {
	position: absolute;
	right: 21.5%;
	top: 46%;
}

.warningPumpTop {
	height: 5.8%;
	left: 40.9%;
	position: absolute;
	top: 0.8%;
}

.warningPumpBottom {
	bottom: 22.4%;
	height: 3.5%;
	left: 35.4%;
	position: absolute;
}

.detailsPanel {
	position: absolute;
	left: 0;
	top: 0;
	height: 0;
	width: 100%;
	min-width: 325px;
	border-left: 1px solid #999;
	overflow: hidden;
	z-index: 99999999;
	background-color: #fff;
	height: 100%;
}

.detailsHeader {
	width: 100%;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	line-height: 1.5em;
}

.detailsContent {
	font-size: 0.8em;
	padding: 0 1em;
	overflow-y: scroll;
	height: 93%;
}

#divDetailsContent .RadButton {
	cursor: default !important;
}

#detailsContents {
	height: 100%;
}

.detailsContent table {
	width: 100%;
}

	.detailsContent table td {
		padding: 3px 0;
	}

// .btnDetails {
// 	background-image: url(./assets/images/modules/trennstation/btnReflex.jpg);
// 	background-size: contain;
// 	width: 150px;
// 	height: 26px;
// 	font-size: 16px;
// 	border: none;
// 	color: #fff;
// 	cursor: pointer;
// }

// .closeButton {
// 	width: 45px;
// 	height: 20px;
// 	background-image: url(Images/modalClose.png);
// 	background-size: contain;
// 	cursor: pointer;
// 	transition: all 0.5s ease;
// }

.closeButton:hover {
    opacity: 0.8;
}

// #divHelperControls {
// }

.divSchema {
	position: relative;
	text-align: center;
	position: relative;
	width: 434px;
	height: 415px;
	margin-left: 30px;
}

// .schemaImage {
// 	display:none;
// 	border: 2px solid #0053a3;
// 	position: relative;
// 	width: 345px;
// 	height: 415px;
// 	background-image: url(/Images/Devices/Trennstation/StatusSettings/STS_UntenRechts.png);
// 	background-position: center;
// 	background-size: 130%;
// 	background-repeat: no-repeat;
// 	margin-right:30px;
// }

.divSettingsWrapper {
	border: 2px solid #0053a3;
	width: 303px;
	height: 415px;
}

#divSettingsHeader {
	line-height: 2em;
	background-color: #0053a3;
	color: #fff;
	text-align: center;
}

.divSettings {
	height: 378px;
	position: relative;
}

.divSchemaOffline {
	position: relative;
	text-align: center;
	margin: 0 auto;
	border: 2px solid #fb2525;
	position: relative;
	background-color: #000;
	padding-left: 10%;
	display: none;
}

.divAutoMode {
	cursor: not-allowed;
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
}

// .arrowGreenMode {
// 	position: absolute;
// 	top: 5px;
// 	right: 5px;
// 	height: 25%;
// 	width: 35px;
// 	background-image: url(/Images/Devices/Trennstation/StatusSettings/green_arrow.png);
// 	background-repeat: no-repeat;
// 	background-size: contain;
// 	background-position: right;
// }

#imgSchema {
	width: 100%;
	z-index: 0;
}

/* EDIT */
.editPanelP1 {
}

.editPanelP1 th {
    text-align: left;
}

.editHeader {
	width: 50px;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	line-height: 1.5em;
}

.btnEditeP1 {
	height: 4%;
	left: calc(29% - 25px);
	position: absolute;
	bottom: 26%;
	z-index: 99999999;
}

#editPump1 {
	position: absolute;
	width: 150px;
	min-height: 120px;
	left: calc(29% - 200px);
	bottom: 23%;
	max-height: 100px;
	border: 1px solid #999;
	overflow: hidden;
	z-index: 99999999;
	background-color: #f0f0f0;
	padding-left: 5px;
}

.btnEditeP2 {
	height: 4%;
	left: calc(39.9% - 25px);
	position: absolute;
	bottom: 26%;
	z-index: 99999999;
}

#editPump2 {
	position: absolute;
	width: 150px;
	min-height: 120px;
	left: calc(39.9% - 200px);
	bottom: 23%;
	max-height: 100px;
	border: 1px solid #999;
	overflow: hidden;
	z-index: 99999999;
	background-color: #f0f0f0;
	padding-left: 5px;
}

.btnUebe1 {
	position: absolute;
	height: 4%;
	bottom: 59%;
	right: calc(43.8% + 5px);
	z-index: 99999999;
}

#editUeber1 {
	position: absolute;
	width: 150px;
	min-height: 120px;
	right: calc(49% + 50px);
	bottom: 25%;
	max-height: 100px;
	border: 1px solid #999;
	overflow: hidden;
	z-index: 99999999;
	background-color: #f0f0f0;
	padding-left: 5px;
}

.btnUebe2 {
	position: absolute;
	height: 4%;
	bottom: 26%;
	right: calc(38% + 5px);
	z-index: 99999999;
}

#editUeber2 {
	position: absolute;
	width: 150px;
	min-height: 120px;
	right: calc(38% + 50px);
	bottom: 25%;
	max-height: 100px;
	border: 1px solid #999;
	overflow: hidden;
	z-index: 99999999;
	background-color: #f0f0f0;
	padding-left: 5px;
}

.btnUebe3 {
	position: absolute;
	height: 4%;
	bottom: 26%;
	right: calc(27% + 5px);
	z-index: 99999999;
}

#editUeber3 {
	position: absolute;
	width: 150px;
	min-height: 120px;
	right: calc(27% + 50px);
	bottom: 25%;
	max-height: 100px;
	border: 1px solid #999;
	overflow: hidden;
	z-index: 99999999;
	background-color: #f0f0f0;
	padding-left: 5px;
}

#divDetailsContent .RadButton {
	cursor: default !important;
}

.edittable {
	margin-left: 5%;
	margin-top: 10%;
}

	.edittable table {
		width: 120px;
	}

		.edittable table td {
			padding-bottom: 20px;
		}

.btnSeAll {
	min-width: 50px;
}
/**/

/*Button*/
.styled-button-10 {
	background: #8BC636;
	background-image: -webkit-linear-gradient(top, #8BC636, #8BC636);
	background-image: -moz-linear-gradient(top, #8BC636, #8BC636);
	background-image: -ms-linear-gradient(top, #8BC636, #8BC636);
	background-image: -o-linear-gradient(top, #8BC636, #8BC636);
	background-image: linear-gradient(to bottom, #8BC636, #8BC636);
	-webkit-border-radius: 16;
	-moz-border-radius: 16;
	border-radius: 16px;
	font-family: Arial;
	color: #ffffff;
	width: 100%;
	padding: 1px 10px 0px 10px;
	border: solid #8ac636 2px;
	text-decoration: none;
}

	.styled-button-10:hover {
		background: #738f49;
		background-image: -webkit-linear-gradient(top, #738f49, #738f49);
		background-image: -moz-linear-gradient(top, #738f49, #738f49);
		background-image: -ms-linear-gradient(top, #738f49, #738f49);
		background-image: -o-linear-gradient(top, #738f49, #738f49);
		background-image: linear-gradient(to bottom, #738f49, #738f49);
		text-decoration: none;
	}
/**/
.schemaButtonOuter {
	height: 100%;
	position: absolute;
	width: 80px;
	left: -84px;
	top: -2px;
	border-left: 2px solid #8bc636;
	border-top: 2px solid #8bc636;
	border-bottom: 2px solid #8bc636;
	background-color: #000;
}

.schemaButton {
	border-bottom: 2px solid #8bc636;
	width: 80px;
	height: 20%;
	position: absolute;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

// .schemaButton1 {
// 	top: 0;
// 	left: 0;
// 	background-image: url('/Images/Devices/Trennstation/StatusSettings/manu_schema.png');
// }

// .schemaButton2 {
// 	top: 20%;
// 	left: 0;
// 	background-image: url('/Images/Devices/Trennstation/StatusSettings/auto_schema.png');
// }

// .schemaButton3 {
// 	border-top: 2px solid #8bc636;
// 	border-bottom: 0;
// 	bottom: 20%;
// 	left: 0;
// 	background-image: url('/Images/Devices/Trennstation/StatusSettings/settings_schema.png');
// }

// .schemaButton4 {
// 	border-top: 2px solid #8bc636;
// 	border-bottom: 0;
// 	bottom: 0;
// 	left: 0;
// 	background-image: url('/Images/Devices/Trennstation/StatusSettings/info_schema.png');
// }

// .schemaButton5 {
// 	border-top: 2px solid #8bc636;
// 	border-bottom: 0;
// 	bottom: 40%;
// 	left: 0;
// 	background-image: url('/Images/Devices/Trennstation/StatusSettings/stop_schema.png');
// }

.tankreflexomat {
	height: 42%;
	left: 32.5%;
	top: 19%;
}

.infoReflexomat {
	left: calc(24% + 5px);
	top: 14.7%;
}

.valveStatusReflexomat {
	left: 26.5%;
	top: 14%;
	width: 50px;
}

.systemPressureReflexomat {
	bottom: 61%;
	right: 36%;
	padding: 0;
}

.hintKompressor {
	height: 6.2%;
	left: 51.3% !important;
	position: absolute;
	top: 14%!important;
	cursor: pointer;
}

.editUeber1Reflexomat {
	bottom: 55%!important;
	right: calc(70% + 50px)!important;
}

.pumpOuter {
	background-color: white;
	border: 2px solid #999;
	border-radius: 45px;
	height: 40px;
	left: 70px; /*29%;*/
	position: absolute;
	top: 210px;/*50%;*/
	width: 40px;
	z-index: 99;
	overflow: hidden;
}

.pumpOverlay {
	background-color: white;
	z-index: 101;
	width: 44px;
	height: 44px;
	opacity: 0.7;
	left: 70px;
	position: absolute;
	top: 210px;
}

.pumpOuter2 {
	top: 62%;
}

.pumpOn {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #8bc636;
}

.pumpOnIcon {
	width: 70%;
	height: 70%;
	background-image: url(/assets/images/modules/trennstation/pump_icon_on.png);
	background-size: contain;
	position: relative;
	left: 6%;
	top: 15%;
}

.pumpOnIcon.up {
		transform: rotate(90deg);
		left: 18%;
		top: 10%;
	}

.pumpOffIcon {
	width: 70%;
	height: 70%;
	background-image: url(/assets/images/modules/trennstation/pump_icon_off.png);
	background-size: contain;
	position: relative;
	left: 6%;
	top: 15%;
}

.pumpOffIcon.up {
		transform: rotate(90deg);
		left: 18%;
		top: 10%;
	}

.pumpOff {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #fff;
}

.pumpIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -15px;
	margin-top: -15px;
	height: 30px;
	z-index: 999;
}

.systemPressure {
	left: 50%;
	margin-left: -100px;
	position: absolute;
	top: -57.2%;
	width: 200px;
}


.btnreset {
	background: #0053a3;
	font-family: Arial;
	color: #ffffff;
	font-size: 10px;
	padding: 5px 5px 5px 5px;
	text-decoration: none;
}


.bobble {
	width: 26px;
	height: 26px;
	border-radius: 13px;
	background-color: orange;
	position: absolute;
	display: none;
}

.bobbleTank {
	top: 150px;
	right: 60px;
}

.bobblePumpe1 {
	bottom: 70px;
	left: 40px;
}

.bobblePumpe2 {
	bottom: 60px;
	left: 100px;
}

.nspOuter {
	background-color: white;
	border: 2px solid #999;
	border-radius: 45px;
	height: 30px;
	left: 278px;
	position: absolute;
	top: 65px;/*18%;*/
	width: 30px;
	z-index: 99;
	overflow: hidden;
}

.nspZu {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center;
	background-image: url(/assets/images/modules/trennstation/nsp_zu.png);
	background-color: white;
	background-size: cover;
	background-repeat: no-repeat;	
}

.nspAuf {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url(/assets/images/modules/trennstation/nsp_auf.png);
	background-color: #8bc636;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;	
}

.tableHead {
	font-size: 12px;
}

.pipeEnd {
	position: absolute;
	top: 132px;
	left: 210px;
	width: 15px;
	height: 20px;
	background-image: url(/assets/images/modules/trennstation/endstueck.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	/* Image is too small -> CSS needed to stop browsers from bluring it. */
	image-rendering: optimizeSpeed;             /*                     */
	image-rendering: -moz-crisp-edges;          /* Firefox             */
	image-rendering: -o-crisp-edges;            /* Opera               */
	image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
	image-rendering: optimize-contrast;         /* CSS3 Proposed       */
	-ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
}

.rwLine {
	width: 142px;
	height: 3px;
	background-color: black;
	position: absolute;
	top: 140px;
	left: 224px;
}

.rwLine2 {
	width: 3px;
	height: 147px;
	background-color: black;
	position: absolute;
	top: 140px;
	left: 363px;
	z-index: 98;
}

.rwLine3 {
	width: 32px;
	height: 3px;
	background-color: black;
	position: absolute;
	top: 284px;
	left: 364px;
}

.rwLine4 {
	width: 3px;
	height: 40px;
	background-color: black;
	position: absolute;
	top: 284px;
	z-index: 9991;
	left: 396px;
}

.flipHorizontal {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipH;
	-ms-filter: "FlipH";
}

.grayLine {
	background-color:#acacac;
	position:absolute;
	width:2px;
	height:3px;
	top:50px;
	left:50px;
}

#pump1FreqIco, #pump2FreqIco {
	display: block;
	width: 100%;
	position: absolute;
	top: 10px;
}

.rslItemsWrapper li:not(:first-child):not(:last-child) span {
	margin-left: -5px;
}

.rslItemsWrapper li:last-child span {
	margin-left: -11px;
}

.defaultTrack .rslItemsWrapper li:not(:first-child):not(:last-child) span {
	margin-left: 0px;
}

.defaultTrack .rslItemsWrapper li:last-child span {
	margin-left: -3px;
}

#pump1ControlButtons, #pump2ControlButtons, #pump3ControlButtons, #nsp1ControlButtons, #nsp2ControlButtons, #drainControlButtons {
	margin-bottom: 5px;
}

.spanWidthSetter {
    display: inline-block;
    width: 135px;
}

.spanRightSetter {    
    float: right;
}