.login-wrapper {
    background: #fff;
    display: flex;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}

.login-flex-wrapper {
    min-height: 100vh;
    flex-grow: 1;
    flex-direction: column;
    box-sizing: border-box;
}

.login-background {
    padding-top: 50px;
    box-sizing: border-box;
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
}

.login-background-grid-container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: [start]1fr [left-gutter]repeat(16,86.6px) [left-gutter]1fr [end];
    grid-template-rows: [top]1fr [top-gutter]repeat(8,64px) [bottom-gutter]1fr [bottom];
    justify-content: center;
    margin: 0 -2%;
    transform: rotate(-6deg) skew(-6deg);

    .grid-1 {
        grid-area: top / start / 8 / end;

        .grid-1a {
            background-image: linear-gradient(white 0%, rgb(244, 244, 245) 33%);
            flex-grow: 1;
        }
    }

    .grid-2 {
        grid-area: 4/4/auto/2;

        .grid-2a {
            box-shadow: inset 0 0 0 2px #e3e8ee;
            flex-grow: 1;
        }
    }

    .grid-3 {
        grid-area: 6 / start / auto / 2;

        .grid-3a {
            background-color: #c62828;
            flex-grow: 1;
        }
    }

    .grid-4 {
        grid-area: 7 / start / auto / 4;

        .grid-4a {
            background-color: #d46954;
            flex-grow: 1;
        }
    }

    .grid-5 {
        grid-area: 6 / 4 / auto / 6;

        .grid-5a {
            background-color: #eee3e4;;
            flex-grow: 1;
        }
    }
    .grid-6 {
        grid-area: 2 / 15 / auto / end;

        .grid-6a {
            background-color: #ff5f3f;;
            flex-grow: 1;
        }
    }

    .grid-7 {
        grid-area: 3 / 14 / auto / end;

        .grid-7a {
            background-color: #c62828;
            flex-grow: 1;
        }
    }

    .grid-8 {
        grid-area: 4 / 17 / auto / 20;

        .grid-8a {
            background-color: #eee3e4;
            flex-grow: 1;
        }
    }

    .grid-9 {
        grid-area: 5 / 14 / auto / 17;

        .grid-9a {
            box-shadow: inset 0 0 0 2px #e3e8ee;
            flex-grow: 1;
        }
    }

    .grid-10 {
        grid-area: 8/18/auto/6;

        .grid-10a {
            background-color: #eee3e4;;
            flex-grow: 1;
        }
    }
}

.login-logo-wrapper {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    z-index: 5;
    
    .login-logo-icon {
        margin-right: auto;
        margin-left: auto;
        display: block;
        width: 50px;        
    }
    .login-logo-text {
        margin-right: auto;
        margin-left: auto;       
        width: 140px;
        padding: 0px; 
        margin-top: 10px; 
        margin-bottom: 0px;
        display: block;
    }

    .login-logo-full {
        margin-right: auto;
        margin-left: auto;
        display: block;
        height: 50px;    
    }
}

.login-content {
    z-index: 1 !important;
}

.login-table {
    width: 100%;
    border-collapse: collapse;
}

.login-card-flex {
    flex-grow: 1;
    z-index: 5;
    border-collapse: collapse;
}

.login-card {
    width: 90%;
    max-width: 500px;
    padding: 48px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-bottom: 30px;
    background: #fff;
    color: #666;
    box-shadow: 0 5px 15px rgb(0 0 0 / 8%);
    box-sizing: border-box;

    form {
        .login-card-title {
            color: #333;
            font-size: 1.5rem;
            line-height: 1.4;            
        }

        .login-card-row {
            padding-top: 32px;
        }
    
        .login-label-s {
            line-height: 20px;
            font-weight: 500;
            font-size: 14px;
            color: #666;
        }

        .login-back-label {
            line-height: 20px;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            padding-top: 15px;
        }

        .login-checkbox {
            height: 16px;
            width: 16px;
            margin-right: 7px;
        }
    
        .login-input, .reset-input {
            -webkit-appearance: none; 
            border: 1px solid #b9b9bb;
            display: flex;
            border-radius: 4px;
            font-size: 16px;
            flex-grow: 1;
            flex-shrink: 1;
            line-height: 28px;
            padding: 8px 16px 8px 16px;
            margin-top: 12px;
            outline-color: #eee3e4 !important;
            width: 100%;
            box-sizing: border-box;
        }   
        
        .login-button {
            -webkit-appearance: none;
            border-radius: 4px;
            font-weight: bold;
            font-size: 16px;
            line-height: 28px;
            width: 100%;
            border-radius: 4px;
            padding: 8px 16px 8px 16px;
            text-align: center;
            background-color: #d46954;
            color: #fff;
            outline-style: none;
            outline-width: 0;
            border: none;
        }

        .login-button:hover {
            background-color: #f09c8c;
        }

        .login-button:active {
            background-color: #a14836;
        }
    }    
}

.login-footer {
    width: 100%;
    padding-bottom: 25px;
    z-index: 99;
    text-align: center;

    .login-footer-label {
        line-height: 20px;
        font-weight: 500;
        font-size: 14px;
        color: #666;
        padding: 10px;    
        text-decoration: none !important;    
    }

    a{
        text-decoration: none !important; 
    }
}

@keyframes shake {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(1em);
    }
    40% {
      transform: translate(-1em);
    }
    60% {
      transform: translate(1em);
    }
    80% {
      transform: translate(-1em);
    }
    100% {
      transform: translate(0);
    }
}

.login-card.shake {
    animation: shake 0.5s infinite;
}